<template>
  <div style="height: inherit">

    <modal-content-data
      v-show="isContentModalVisible"
      :selected_data="contentModal"
      :report_lang="report_lang"
      @close-modal-contents-data="isContentModalVisible = false"
    />


    <!-- List Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <b-button
                v-b-toggle.sidebar-1
                size="sm"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                class="mr-1 d-block d-lg-none"
                v-b-tooltip.hover.top="$t('learning_path.filters')"
              >
                <feather-icon
                  icon="FilterIcon"
                  size="18"
                />
              </b-button>
              <b-button-group class="mr-1" v-if="!loading && !isCatalog">
                <b-button id="select_all-button" variant="secondary" size="sm"
                  @click="$emit('select-all-content', contents)">
                  <span class="d-none d-sm-block">{{$t('learning_path.select_all')}}</span>
                  <feather-icon
                    class="d-block d-sm-none"
                    icon="CheckSquareIcon"
                    size="18"
                  />
                </b-button>
                <b-tooltip
                  v-if="$store.getters['app/currentBreakPoint'] == 'xs'"
                  target="select_all-button"
                  placement="top"
                  triggers="hover"
                >
                  {{$t('learning_path.select_all')}}
                </b-tooltip>
                <b-button variant="outline-secondary" size="sm"
                  id="unselect_all-button"
                  @click="$emit('select-all-content', [])">
                  <span class="d-none d-sm-block">{{$t('learning_path.unselect_all')}}</span>
                  <feather-icon
                    class="d-block d-sm-none"
                    icon="XSquareIcon"
                    size="18"
                  />
                </b-button>
                <b-tooltip
                  v-if="$store.getters['app/currentBreakPoint'] == 'xs'"
                  target="unselect_all-button"
                  placement="top"
                  triggers="hover"
                >
                  {{$t('learning_path.unselect_all')}}
                </b-tooltip>
              </b-button-group>
              <div class="search-results" v-if="!loading">
                {{Object.keys(contents).length}} {{$t('learning_path.results_found')}}
              </div>

            </div>
            <div class="view-options d-flex">

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-secondary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                  v-b-tooltip.hover.top="$t(option.name)"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>

              <template v-if="!isCatalog">
                <div class="floating-button">
                  <b-button
                    size="lg"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="route_contents.length>0? 'success' : 'light' "
                    class="btn btn-icon rounded-circle"
                    v-b-modal.modal-selected_contents
                    v-b-tooltip.hover.top="$t('learning_path.selected_contents')"
                  >
                    <feather-icon
                        icon="LayersIcon"
                        size="20"
                      />
                  </b-button>
                </div>
                
                <b-modal
                  id="modal-selected_contents"
                  :title="$t('learning_path.selected_contents')"
                  ok-only
                  :ok-title="$t('forms.close')"
                >
                  <b-card-text>
                    <selected-contents-list 
                      :isReport="isReport"
                      :route_contents="route_contents"
                      @toggle-content-in-list="toggleContentInList"
                    />
                  </b-card-text>
                </b-modal>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

     <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              :value="searchword"
              @input="(val) => $emit('update:searchword', val)"
              :placeholder="$t('learning_path.searcher_placeholder')"
              class="search-product"
              @keyup.enter="$emit('search')"
            />
            <b-input-group-append>
              <b-button :disabled="loading" variant="flat-secondary" @click="$emit('update:searchword', '')">
                <feather-icon
                  icon="XIcon"
                  class="text-muted cursor-pointer"
                />
              </b-button>
              <b-button :disabled="loading" variant="secondary" @click="$emit('search')">
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>
    
    <!-- Filter Badges -->
    <b-row class="mt-1">
      <b-col cols="12">
        
        <template v-for="filter in filterOptions">
          <template v-for="option in filter.options">
            <b-badge
              class="mr-1 mb-1 cursor-pointer"
              :key="option.id"
              v-if="option.selected"
              pill
              variant="light-primary"
              @click="isReport?$emit('unselect-filter', [filter.name, option.id]):$store.commit('learningPaths/handleFilterSelection', [filter.name, option.id])"
            >
              <span>{{filter.name == 'language_learning'? $t('lang.'+option.name): filter.name == 'content_types'? $t('content_types.'+option.name) : (filter.name == 'intelligence' && option.id=='gamification')?$t('intelligence.gamification'):option.name}}</span>
              <feather-icon
                class="ml-1 "
                icon="XIcon"
                size="16"
              />
            </b-badge>
          </template>
        </template>
      </b-col>
    </b-row>

    <!-- Pagination -->
    <section v-if="!loading">
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="Object.keys(contents).length"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <b-row v-if="loading">
      <b-col cols="12"  >
        <div class="text-center">
          <b-spinner label="Loading..." />
        </div>
      </b-col>
    </b-row>
    <!-- Contents List -->
    <template v-else>
      <p v-if="Object.keys(contents).length == 0">
        {{$t('reports.no_content')}}
      </p>
      <section v-else :class="[itemView, 'wishlist-items']">
        <b-card 
          v-for="(content, idx) in paginatedContents"
          :key="idx"
          class="ecommerce-card"
          no-body
        >
          <div class="text-center d-block pointer" 
            style="min-height:0; "
            @click="isCatalog ? openModal(content.id) : toggleContentInList(content)">
            <div style="position: relative;">
              <b-img
                :alt="`${content.title}-${idx}`"
                fluid
                class="card-img-top"
                :src="content.thumbnail_url"
                @error="$event.target.src=require('@/assets/images/placeholders/placeholder_actividad.png')"
              />
              <img
                :src="require('@/assets/images/content_types/'+content.content_type+'.png')"
                @error="$event.target.src=require('@/assets/images/content_types/activity.png')"
                style="width: 30%; height: 30%; position: absolute; bottom: 40%; right: 35%;">
              </div>
              
          </div>

          <b-card-body>
            <h6 class="pointer" @click="isCatalog ? openModal(content.id) : toggleContentInList(content)">
              <b-link class="text-body">
                {{content.title ? content.title.length > 22 ? content.title.slice(0, 22) + '...' : content.title : '...'}} 
              </b-link>
            </h6>
           
            <b-card-text class="item-description">
              {{ content.description }}
            </b-card-text>
            
            <div class="mb-1 item-type">
              <b-badge
                pill
                variant="light-success"
              >
                {{$t('content_types.'+content.content_type) }} 
              </b-badge>
            </div>
          </b-card-body>

          <div class="item-options text-center">
            <b-button
              v-if="!isCatalog"
              :variant="contentisInList(content)?'success':'primary'"
              tag="a"
              style="font-weight:normal"
              class="btn-wishlist"
              @click="toggleContentInList(content)"
            >
              <feather-icon
                class="m-0"
                :icon="contentisInList(content)?'CheckSquareIcon':'SquareIcon'"
              />
              <span class="ml-50 item-options-text">{{contentisInList(content)?$t('learning_path.remove'):$t('learning_path.add')}}</span>
            </b-button>
            <b-button
              variant="secondary"
              tag="a"
              style="font-weight:normal"
              class="btn-wishlist"
              @click="openModal(content.id)"
            >
              <feather-icon
                class="m-0"
                icon="InfoIcon"
              />
              <span class="ml-50 item-options-text">{{ $t('learning_path.information') }}</span>
            </b-button>


          </div>
        </b-card>
      </section>
    </template>
    
     <!-- Pagination -->
    <section v-if="!loading">
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="Object.keys(contents).length"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>


    <b-sidebar
      id="sidebar-1"
      shadow
      bg-variant="white"
      backdrop
    >
      <left-filter-sidebar 
        @contents-hierarchy-selected="selectedContentsHierarchy"
        @search="search"
        :contentsHierarchy="contentsHierarchy"
        :filterOptions="filterOptions"/>
    </b-sidebar>

  </div>
</template>

<script>
import ModalContentData from '@/views/modals/ModalContentData.vue'
import LeftFilterSidebar from'./LeftFilterSidebar.vue'
import {
  BBadge, BDropdown, BDropdownItem, BFormRadioGroup, BFormRadio, BRow, BCol, BInputGroup, BInputGroupAppend, 
  BFormInput, BCard, BCardBody, BLink, BImg, BCardText, BButton, BPagination, BSpinner,
  BTooltip, VBTooltip, BButtonGroup,
  BSidebar,
  VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SelectedContentsList from './SelectedContentsList.vue'

export default {
  components:{
    BTooltip,
    ModalContentData,
    BSidebar,
    SelectedContentsList,
    LeftFilterSidebar,
    BBadge, BDropdown, BButtonGroup, BDropdownItem, BFormRadioGroup, BFormRadio, BRow, BCol, BInputGroup, BInputGroupAppend, 
    BFormInput, BCard, BCardBody, BLink, BImg, BCardText, BButton, BPagination, BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
  props:{
    isCatalog:{
      type: Boolean,
      default: false,
      required: false
    },
    isReport:{
      type: Boolean,
      default: false,
      required: false
    },
    loading:{
      type: Boolean,
      required: false,
      default: false,
    },
    report_lang:{
      type: String,
      required: false,
      default: ''
    },
    searchword: {
      type: String,
      required: true
    },
    filterOptions:{
      type: Array,
      required: true
    },
    contentsHierarchy:{
      type: Array,
      required: false,
      default: () => []
    },
    route_contents:{
      type: Array,
      required: true
    },
    contents:{
      type: [Array, Object],
      required: true
    },
    sortedContents:{
      type: Array,
      required: false,
      default: () => []
    }    
  },
  data(){
    return {
      filters:{
        page: 1,
        perPage: 18,
      },
      itemView:'mini-grid-view',
      itemViewOptions: [
        /* { icon: 'GridIcon', value: 'grid-view' }, */
        { icon: 'GridIcon', value: 'mini-grid-view', name:'layout.change_to_grid_view' },
        { icon: 'ServerIcon', value: 'list-view', name:'layout.change_to_table_view' },
      ],
      selected_contents:[],

      contentModal: null,
      isContentModalVisible: false
    }
  },
  watch:{
    itemView(){
      if(this.itemView == 'mini-grid-view'){
        this.filters.perPage = 18
        this.filters.page = 1
      } else {
        this.filters.perPage = 5
        this.filters.page = 1
      }
    }
  },
  computed:{
    paginatedContents(){
      if(this.sortedContents.length>0){
        // sortedContents es la lista de ids de contenidos ordenados
        const mycontents = this.sortedContents.map(c_id => this.contents[c_id])
        return mycontents.slice(this.filters.perPage * (this.filters.page - 1), this.filters.perPage * this.filters.page);
      } else {
        return Object.values(this.contents).slice(this.filters.perPage * (this.filters.page - 1), this.filters.perPage * this.filters.page);
      }
    }
  },
  methods:{
    selectedContentsHierarchy(contents){
      this.$emit('contents-hierarchy-selected', contents)
    },
    openModal(content_id) {
      this.contentModal = content_id;
      this.isContentModalVisible = true;
      this.$bvModal.show('modal-contents-data')
    },
    search(){
      this.$root.$emit('bv::toggle::collapse', 'sidebar-1')
      this.$emit('search')
    },
    toggleContentInList(content){
      this.$emit('toggle-content-in-list', content)
    },
    contentisInList(content){
      let selected_contents = this.route_contents.map(c => c.id)
      if(selected_contents.includes(content.id))
      {
        return true
      } else {
        return false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-ecommerce.scss";

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.responsive-grid-view{
  grid-column: 1fr 1fr 1fr 1fr;
}

.mini-grid-view{
    &:not(.wishlist-items) {
      margin-top: 2rem;
    }
}
.mini-grid-view {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    &.wishlist-items {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    .ecommerce-card {
      overflow: hidden;
      border-radius: 10px;
          text-align: center;
      // Item Image
      .item-img {
        padding-top: 0;
        min-height: 64px;
        display: flex;
        align-items: center;
      }

      // Item content wrapper
      .item-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      }


      // Hiding list view items
      .item-description,
      .item-type,
      .item-options-text,
      .shipping,
      .item-company,
      .item-options .item-price {
        display: none;
      }

      .item-options {
        display: flex;
       /*  flex-wrap: wrap; */

        .btn-cart,
        .btn-wishlist {
          flex-grow: 1;
          border-radius: 0;
          padding: 0.786rem 0.5rem;;
        }
      }

      .item-name {
        margin-top: 0.75rem;
      }

      // Item Description
      .item-description {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-top: 0.2rem;
      }

      .item-price {
        font-weight: $font-weight-bolder;
      }

      .card-body {
        padding: 0.5rem;
      }
    }
}
@include media-breakpoint-down(lg) {
  .ecommerce-application {
    .mini-grid-view.wishlist-items {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }
}
@include media-breakpoint-down(md) {
  .ecommerce-application {
    .mini-grid-view {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }
}
@include media-breakpoint-down(sm) {
  .ecommerce-application {
    .mini-grid-view.wishlist-items {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }
}
@include media-breakpoint-down(xs) {
  .ecommerce-application {
    // Grid View
    .mini-grid-view,
    .mini-grid-view.wishlist-items {
      grid-template-columns: 1fr 1fr 1fr;

      .ecommerce-card{
        .item-options {
          flex-wrap: wrap;
        }
      }
    }
  }
}
</style>